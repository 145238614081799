@import _/export
.more
    display: inline-block
    line-height: 36px
    color: $main
    border: 1px solid 
    +br(20px)
    +bg(ar-more,25px)
    padding-left: 47px
    padding-right: 30px
    +fix(transition,all 0.3s)
    &:hover
        background-color: $main
        color: #fff
        background-image: url("../img/ar-more2.png")
.title-bar
    border-bottom: 1px solid $line
    text-align: center
    font-size: 0
    margin-bottom: 50px
    &.bdfff
        border-color: $main
        *
            color: $main
    h2
        font-size: 22px
        color: $main
        display: inline-block
        border-bottom: 1px solid #ff9700
        margin-bottom: -1px
        padding-bottom: 20px
        padding-top: 45px
        p 
            font-size: 16px
            margin-top: 5px
            font-weight: normal
.title-bar2
    text-align: center
    +bg(titlebg,center)
    height: 210px
    padding-top: 75px
    
    h2 
        font-size: 50px
        color: $main
        p 
            font-size: 30px 
            color: #333
            margin-top: 10px
//to top
.entrybox 
    width: 48px
    height: auto
    position: fixed
    bottom: 40px
    right: 20px
    z-index: 5

.top 
    width: 48px
    height: 48px
    margin-bottom: 10px
    position: relative
.top 
    background: url("../img/pop-top.png") no-repeat
    display: none

// ------------------------------
// ︿(￣︶￣)︿ 
// ------------------------------
.about-wrap
    padding-top: 50px
    
    +bgj(aboutbg,center)
    background-size: cover
.aboutpic
    +bgj(about-bg,left)
    width: 690px
    height: 377px
    padding-left: 90px
    &:hover
        .videobox
            &:before
                +bgo(0.5)
            
    .pic
        height: 100%
    .videobox
        position: relative
        height: 100%
        &:before
            content: ''
            position: absolute
            top: 0
            left: 0
            width: 100%
            height: 100%
            +fix(transition,all 0.3s)
            +bg(video,center)
            +bgo(0.3)
            background-size: 70px auto
            cursor: pointer

.aboutinfo
    padding: 30px
    padding-top: 45px
    overflow: hidden
    background-color: #FFF  
    height: 377px
    position: relative
    &:after
        content: ''
        height: 50px
        width: 4px
        background-color: $main
        position: absolute
        right: 0
        top: 50%
        margin-top: -25px
    h5 
        font-size: 16px
        color: #333
        font-weight: bold
        margin-bottom: 25px
    .txt
        line-height: 28px
        height: 168px
        overflow: hidden
        text-indent: 2em
        margin-bottom: 35px
// ------------------------------
// ︿(￣︶￣)︿ 
// ------------------------------
.news-wrap
    background-color: #FFF
    
.newsleft
    height: 350px
    .pic
        position: relative
        height: 100%
        display: block
        +fix(transition,all 0.3s)
        &:hover
            +bs(0 0 5px 2px $line)
            +fix(transform,scale(1.01))
    p 
        position: absolute
        bottom: 0
        width: 100%
        +bgo(0.5)
        line-height: 50px
        font-size: 16px
        color: #fff
        padding: 0 25px
        +tp(100%)
.newslist
    overflow: hidden
    padding-bottom: 6px
    li 
        margin-bottom: 10px
    .tit
        display: block
        font-size: 16px
        +tp(100%)  
        margin-bottom: 5px
    .txt-box
        font-size: 12px
        line-height: 20px
        +mp(2)
        color: #999
        margin-bottom: 5px
    .date
        font-size: 12px
        color: #999
        line-height: 20px  
// ------------------------------
// ︿(￣︶￣)︿ 
// ------------------------------
.food-wrap

    +bgj(aboutbg,center)
    background-size: cover
    .container
        width: 1225px
.foodlist2,.foodlist3
    display: none!important
.foodlist
    
    .li 
        width: 285px
        height: 240px
        position: relative
        margin-right: 20px
        float: left
        margin-bottom: 20px
        cursor: pointer
        &:hover
            +bs(0 0 5px 2px #999)
            img 
                +fix(transform,scale(1.01))
        &:before
            content: ''
            position: absolute
            top: 0
            left: 0
            width: 100%
            height: 100%
            // +bg(foodbg2,center)
            z-index: 10

                
        .a
            display: block
            height: 100%
            line-height: 210px
            text-align: center
            position: relative
            
            img 
                width: 100%
                height: 100%
                +fix(transition,all 0.3s)
    .intro
        position: absolute
        width: 410px
        height: 326px
        top: 0
        left: 0
        bottom: 0
        right: 0
        margin: auto
        line-height: 24px
        color: #ebe4d1
        +bg(foodintro,center)
        padding: 0 35px
        padding-top: 75px
        .tit
            font-size: 22px
            margin-bottom: 15px
        .txt
            height: 96px
            overflow: hidden
// ------------------------------
// ︿(￣︶￣)︿ 
// ------------------------------
.join-wrap
    +bgj(joinbg,center)
    background-size: cover

// ------------------------------
// ︿(￣︶￣)︿ 
// ------------------------------
.joinform-wrap

    padding-top: 25px
    padding-bottom: 25px
.formjoin
    width: 1140px
    +bgo(0.2)
    padding: 0 40px
    padding-top: 40px
    padding-bottom: 40px
    margin-top: 30px
    .in-box
        width: 264px
        float: left
        margin-right: 28px
    .submit-box
        margin-right: 0
        width: 184px

    input 
        height: 50px
        width: 100%
        border: none
        padding: 0 10px
    .submit 
        font-size: 18px
        background-color: $main
        color: #fcd7a1
        &:hover
            background-color: lighten($main,5%)
.lastsection
    +bgj(formbg,center)
    background-size: cover
    .fp-tableCell
        vertical-align: bottom
.footer
    position: static
.footcontact
    padding-top: 25px
    padding-bottom: 25px
#fp-nav.right
    right: 0!important
#fp-nav,.fp-slidesNav 
    ul 
        li 
            a 
                span
                    background-color: $main!important
.fp-controlArrow
    margin-top: 60px
.fp-controlArrow.fp-prev
    border-right-color: $main!important
    opacity: 0.5
    // left: 50px!important
.fp-controlArrow.fp-next
    border-left-color: $main!important
    opacity: 0.5
    // right: 50px!important
.fp-slide 
    .fp-tableCell
        vertical-align: top
// ------------------------------
// ︿(￣︶￣)︿ 平板和手机
// ------------------------------
@media screen and (max-width: 1200px)
    .title-bar
        margin-bottom: 25px
        h2
            padding-top: 15px
            padding-bottom: 15px    
    .aboutpic
        width: 100%  
    .aboutinfo
        height: auto 
        padding-top: 20px
        .txt
            height: 56px
            margin-bottom: 20px
    .newsleft
        margin-bottom: 15px
    .foodlist 
        margin-right: 0

    .formjoin 
        width: 100%
        .in-box
            width: 100%



// ------------------------------
// ︿(￣︶￣)︿ 手机
// ------------------------------

@media screen and (max-width: 768px)
    .foodlist1,.foodlist2
        display: none!important
    .foodlist3
        display: block!important
    .food-wrap
        .container
            width: auto
    .about-wrap
        padding: 0
        padding-top: 15px
        background-image: none
        
    .title-bar
        margin-bottom: 15px
        h2
            padding-top: 10px
            padding-bottom: 10px
    .title-bar2
        height: auto
        text-align: center
        font-size: 0
        margin-bottom: 15px
        background: none
        padding-top: 15px
        h2
            font-size: 22px
            color: $main
            display: inline-block
            border-bottom: 1px solid #f8daaf
            margin-bottom: -1px
            padding-bottom: 10px
            padding-top: 10px
            p 
                font-size: 16px
                margin-top: 5px
                font-weight: normal
    .food-wrap
        padding-bottom: 20px
    .foodlist 
        
        .li
            display: block
            margin: 0 auto
            margin-bottom: 15px
            float: none

        .intro
            display: none

    .news-wrap
        .title-bar
            display: none
    .join-wrap
        padding-bottom: 30px  
    .joinform-wrap
        background: none
        padding-top: 0
        padding-bottom: 0
    .formjoin
        margin-top: 0
        padding: 15px
        padding-bottom: 0
        .in-box
            margin-bottom: 15px
    .aboutpic
        height: 177px
    .newsleft
        height: 200px
    .aboutinfo
        padding-top: 30px
// ------------------------------
// ︿(￣︶￣)︿ 平板
// ------------------------------

@media screen and (max-width: 1200px) and (min-width: 768px) 
    .foodlist1,.foodlist3
        display: none  !important
    .foodlist2
        display: block!important
    .title-bar2
        background-size: auto 150px 
        height: 150px
        padding-top: 40px
        h2 
            font-size: 40px 
            p 
                font-size: 25px
    .foodlist 
        text-align: center
        padding: 0 40px
        .li
            display: inline-block
            float: none
            margin: 0 10px
            margin-bottom: 20px


    .formjoin
        padding-bottom: 20px
        width: 80%
        margin-left: auto
        margin-right: auto
        padding-left: 150px
        padding-right: 150px
        margin-top: 0
        .in-box
            margin-bottom: 20px
    .food-wrap
        .container
            width: 970px